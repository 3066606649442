
import mainSec_mo from './image/mainSec_mo.png';
import mainSec_pc from './image/mainSec_pc.png';
import mainSec_txt from './image/mainSec_txt.png';
import mainSec_arrow from './image/mainSec_arrow.png';

import logo_koreaTinter from './image/logo_koreatinter.png';
import logo_koreatinter_white from './image/logo_koreatinter_white.png';
import logo_autobahn_color from './image/logo_autobahn_color.png';
import logo_autobahn_white from './image/logo_autobahn_white.png';
import logo_autobahnMo from './image/logo_autobahnMo.png';
import logo_optimum_color from './image/logo_optimum_color.png';
import logo_optimum_white from './image/logo_optimum_white.png';
import logo_optimumMo from './image/logo_optimumMo.png';
import logo_tint09_color from './image/logo_tint09_color.png';
import logo_tint09_white from './image/logo_tint09_white.png';
import logo_tint09Mo from './image/logo_tint09Mo.png';

import sns_facebook from './image/sns_facebook.png';
import sns_instagram from './image/sns_instagram.png';
import sns_youtube from './image/sns_youtube.png';




function App() {
  return (
    <div className="wrap">
      <div className="mainSection">
        <p className="mainSec_img">
          <img src={mainSec_mo} alt="메인" className="mainSec_mo"/>
          <img src={mainSec_pc} alt="메인" className="mainSec_pc"/>
        </p>
        <p className="mainSec_txt">
          <img src={mainSec_txt} alt="코리아틴터"/>
        </p>
        <p className="mainSec_arrow">
          <img src={mainSec_arrow} alt="아래쪽 화살표"/>
        </p>
      </div>

      <h1>
        <a href="/" className="TitSectionBox">
          <img src={logo_koreaTinter} alt="코리아틴터" />
        </a>
      </h1>

      <div className="logoSection">
        <div className="logoSecWrap">
          <a href="https://www.autobahnkorea.com/" target='_blank' rel="noreferrer" >
            <img src={logo_autobahnMo} alt="autobahn" className="logoSecInfoBgImg"/>
            <div className="logoSecInfoBox">
              <p className="logoSecInfo_logo">
                <img src={logo_autobahn_white} alt="autobahn" />
                <img src={logo_autobahn_color} alt="autobahn" />
              </p>
              <p className="logoSecInfo_tit">
                오토반 썬팅필름
              </p>
              <p className="logoSecInfo_txt">
                오토반의 고품질 필름은 <br/>
                유해한 자외선을 차단하고 눈부심을 줄이며 <br/>
                가시성을 손상시키지 않으면서 <br/>
                추가된 프라이버시를 제공합니다.
              </p>
              <p className="logoSecInfo_more">
                더보기
              </p>
            </div>
          </a>
        </div>

        <div className="logoSecWrap">
          <a href="https://www.optimumwindowfilm.com/" target='_blank' rel="noreferrer" >
            <img src={logo_optimumMo} alt="optimum" className="logoSecInfoBgImg"/>
            <div className="logoSecInfoBox">
              <p className="logoSecInfo_logo">
                <img src={logo_optimum_white} alt="optimum" />
                <img src={logo_optimum_color} alt="optimum" />
              </p>
              <p className="logoSecInfo_tit">
                옵티멈 썬팅필름
              </p>
              <p className="logoSecInfo_txt">
                Optimum Window Film <br/>
                For Exclusive Once라는 슬로건으로 <br/>
                국내 뿐 아니라 해외에서도 활발히 활동하는 <br/>
                글로벌 틴터 그룹입니다.
              </p>
              <p className="logoSecInfo_more">
                더보기
              </p>
            </div>
          </a>
        </div>

        <div className="logoSecWrap">
          <a href="https://pbr3558.cafe24.com/" target='_blank' rel="noreferrer" >
            <img src={logo_tint09Mo} alt="tint09" className="logoSecInfoBgImg"/>
            <div className="logoSecInfoBox">
              <p className="logoSecInfo_logo">
                <img src={logo_tint09_white} alt="tint09" />
                <img src={logo_tint09_color} alt="tint09" />
              </p>
              <p className="logoSecInfo_tit">
                틴트 공구
              </p>
              <p className="logoSecInfo_txt">
                코리아 틴터에서 엄선한 <br/>
                썬팅 시공 시 편안하게 사용할 수 있는 <br/>
                필수 공구들을 합리적인 가격에 <br/>
                만날 수 있습니다.
              </p>
              <p className="logoSecInfo_more">
                더보기
              </p>
            </div>
          </a>
        </div>
      </div>

      <footer>
        <div className="footerIn">
          <p>
            <img src={logo_koreatinter_white} alt="코리아틴터"/>
          </p>
          <div className="snsBox">
            <a href="https://www.facebook.com/KoreaTintercom/" target='_blank' rel="noreferrer">
              <img src={sns_facebook} alt="Facebook"/>
            </a>
            <a href="https://www.instagram.com/optimum_korea/" target='_blank' rel="noreferrer">
              <img src={sns_instagram} alt="Instagram"/>
            </a>
            <a href="https://www.youtube.com/user/solargardkorea" target='_blank' rel="noreferrer">
              <img src={sns_youtube} alt="YouTube"/>
            </a>
          </div>
        </div>
      </footer>
    </div>


  );
}

export default App;
